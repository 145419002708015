<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img :src="require(`@/assets/images/logo/logo.png`)" height="30"></b-img>
        <h2 class="brand-text text-dark ml-1">BlackHorse</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2"> Black Horse is Brown ! 🐎 </b-card-title>
          <b-card-text class="mb-2"> Please sign-in to your account</b-card-text>

          <!-- form -->
          <b-form class="auth-login-form mt-2" @submit.prevent="login">
            <!-- email -->
            <b-form-group label="Username">
              <b-form-input v-model="username" id="Username" name="Username" :disabled="loader" placeholder="username" />
            </b-form-group>

            <b-form-group>
              <b-input-group class="input-group-merge">
                <b-form-input v-model="password" id="Password" name="Password" class="form-control-merge" :disabled="loader" :type="passwordFieldType" placeholder="Password" />
                <b-input-group-append is-text>
                  <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <!-- submit buttons -->
            <b-button type="submit" variant="primary" block :disabled="loader">
              <b-spinner v-if="loader" small />
              <span v-else>Sign in</span>
            </b-button>
          </b-form>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@store";

export default {
  components: {
    VuexyLogo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      password: null,
      username: null,
      remember: false,
      sideImg: require("@/assets/images/pages/login-v2.svg"),

      // validation rules
      required,
      email,
      loader: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    async login() {
      this.loader = true;
      try {
        await this.$store.dispatch("auth/LOGIN", { username: this.username, password: this.password });
        this.$router.push({ name: "dashboard" });
      } catch (error) {
        this.showToast("error", error);
      } finally {
        this.loader = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
