<template>
  <div>
    <b-button variant="primary" class="mb-1" block @click.stop="publish(localOrder)">Publish unpublished posts</b-button>
    <b-table
      ref="postTable"
      v-if="localOrder"
      :current-page="table.paging.curPage"
      :per-page="table.paging.pageSize"
      :items="localOrder.orderDetail"
      :fields="table.fields"
      sort-by="workDate"
      small
    >
      <template #cell(no)="row">
        {{ row.index + 1 + (table.paging.curPage - 1) * table.paging.pageSize }}
      </template>
      <template #cell(target)="data">
        <span class="d-block">{{ data.item.targetUrl }}</span>
        <small class="text-primary">{{ data.item.anchor }}</small>
      </template>
      <template #cell(workDate)="data">
        {{ data.item.workDate | date }}
      </template>
      <template #cell(postLink)="data">
        <a v-if="data.item.postLink" :href="data.item.postLink" target="_blank">{{ shortenString(data.item.postLink) }}</a>
      </template>
      <template #cell(action)="data">
        <b-button v-if="data.item.postId" size="sm" :disabled="data.item.loader" variant="danger" @click.stop="removePost(data.item)">Remove Post</b-button>
        <b-button v-else size="sm" variant="primary" :disabled="data.item.loader" @click.stop="publishPost(data.item)">Publish</b-button>
      </template>
    </b-table>
    <b-pagination v-if="localOrder" v-model="table.paging.curPage" align="center" :total-rows="localOrder.orderDetail.length" :per-page="table.paging.pageSize" />
  </div>
</template>

<script>
import api from "@api";

export default {
  name: `PostDetail`,
  props: { order: { type: Object, required: true } },
  mounted() {},
  data: function () {
    return {
      localOrder: null,
      table: {
        fields: [
          { key: `no`, label: `#` },
          { key: `domain`, label: `Domain` },
          { key: `target`, label: `Target` },
          { key: `workDate`, label: `Work Date` },
          { key: `postLink`, label: `Link` },
          { key: `action`, label: `` },
        ],
        paging: {
          curPage: 1,
          pageSize: 25,
        },
      },
      loader: false,
    };
  },
  async mounted() {
    await this.loadOrder();
  },
  methods: {
    async publish(order) {
      try {
        api.get(`order/publish-posts`, { orderNo: order.orderNo });
        this.showToast(`success`, `Post is being published`);
      } catch (error) {
        this.showToast(`error`, error);
      }
    },
    async loadOrder() {
      this.loader = true;
      try {
        this.localOrder = await api.get(`order/${this.order.orderNo}`);
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },
    async publishPost(rec) {
      rec.loader = true;
      this.$refs.postTable.refresh();
      try {
        await api.get(`order/publish-post/${rec.id}`);
        this.showToast(`success`, `Post is published`);
        this.loadOrder();
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        rec.loader = false;
        this.$refs.postTable.refresh();
      }
    },
    async removePost(rec) {
      rec.loader = true;
      this.$refs.postTable.refresh();
      try {
        await api.delete(`order/post/${rec.id}`);
        this.showToast(`success`, `Post is removed`);
        this.loadOrder();
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        rec.loader = false;
        this.$refs.postTable.refresh();
      }
    },
  },
};
</script>
